import React from "react";
import { Section } from "../../../layout/Section";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import HeadPara from "../../../HeadPara";
import ArticleTag from "../../../ArticleTag";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { Box, Flex } from "@chakra-ui/react";
import { ContactPatriciaCard } from "../../../cards/ContactPatriciaCard";
import ArticleQuote from "../../../ArticleQuote";
import QuoteAuthor from "../../../QuoteAuthor";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";
import { Carousel } from "../../../layout/ArticleCarousel/Carsousel";

export const WasalineArticle: React.FC = () => {
  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <AlignmentContainer>
          <HeadPara
            large
            para="Wasaline's Power BI for Data-driven optimization"
          />
          <ArticleTags>
            <ArticleTag tag="Customer" value="Wasaline" />
            <ArticleTag tag="Project" value="Power BI" />
            <ArticleTag
              tag="What we worked on"
              value="Data analytics and visualisation"
            />
          </ArticleTags>
          <BodyText bold>
            Wasaline is the northernmost shipping line in the world, which
            transports passengers and freight daily between Vaasa in Finland and
            Umeå in Sweden. In addition to being the most environmentally
            friendly sea route between Finland and Sweden, the route is also
            important for security of supply. The company's hybrid ferry, Aurora
            Botnia, is equipped with dual-fuel engines and batteries. Aurora
            Botnia is the most environmentally friendly passenger ferry in the
            world and Wasaline invests in sustainability in all its processes.
            The Key Flag has been awarded both to Aurora Botnia and Wasaline’s
            services as a recognition of Finnish work.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/wasaline-01.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Marica Öst, Indevit and Jennie Hinz, Wasaline" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Challenge" />
          <BodyText>
            In the last few years, Wasaline has increased passenger and freight
            traffic with 25%, which brought both internal and external demands.
            It became evident that they needed to enhance their ability to
            generate statistics and analyses to meet new requirements
            effectively.
          </BodyText>
          <BodyText>
            Encountering difficulties accessing data from their systems and
            generating relevant reports to support their operation, Wasaline
            turned to Indevit to address their challenges. Wasaline urgently
            required automation to streamline processes and reduce manual work
            as their system was lacking flexibility for informed decision-making
            based on data analysis. Indevit's expertise became essential to
            support Wasaline to manage and optimize their data in the middle of
            rapid growth challenges.
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer>
          <HeadPara medium para="Kick off" />
          <BodyText>
            Wasaline preferred to collaborate with a small, local company that
            could provide personalized service and attention. The two
            organizations began with an evaluation meeting, establishing a solid
            understanding. At Indevit a combination of the following factors was
            necessary: good foundational knowledge of business systems,
            understanding of Wasaline's data, an extensive company-wide
            expertise in efficient data analysis and management, and the ability
            to tailor a solution to Wasaline's specific needs.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer marginTop="unset">
          <StaticImage
            alt=""
            src="../../../../images/wasaline-03.jpg"
            placeholder="none"
          />
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Collaboration" />
          <BodyText>
            The collaboration with Indevit resulted in a solution that was
            optimal for Wasaline’s needs. Choosing Power BI, a platform Wasaline
            was familiar with, proved beneficial in utilizing the tool to meet
            current needs. Indevit understood the needs well and presented a
            sample report demonstrating how Wasaline could maximize the usage of
            the tool. The favourable cost structure made the solution even more
            attractive. According to Wasaline’s Freight director Tony Ehrs, the
            collaboration with Indevit resulted in a solution tailored to their
            needs.  
          </BodyText>
          <ArticleQuote body=" “With their expertise in Power BI, they provided the support and knowledge we needed from a partner to fully utilize the tool. Additionally, Indevit brought the deeper analytical expertise that we lacked internally, enabling more advanced analyses and better decision-making”" />
          <QuoteAuthor body="– Tony Ehrs, Wasaline" />
          <BodyText>
            The collaboration with Indevit has been positive in several ways.
            Wasaline have appreciated Indevit’s agile work methodology and quick
            response to their needs. Indevit's competent staff and professional
            approach have contributed to a successful collaboration without
            surprises. Wasaline’s Analytics and Systems Manager, CSO, Jennie
            Hinz comments that Indevit’s close and personal dialogue, along with
            their effort to meet Wasaline’s expectations, have built a trusting
            relationship between both organisations.
          </BodyText>
          <BodyText>
            “We have appreciated the prompt support and assistance whenever
            needed. It has truly contributed to a smooth and efficient process”,
            says Jennie.
          </BodyText>
          <BodyText>
            Marica reflects that Wasaline is perceived as a highly positive
            collaborator by Indevit. The collaboration is characterized by a
            close and friendly relationship with a low threshold for
            communication.
          </BodyText>
          <BodyText>
            “We appreciate Wasaline's clear requests and objectives, which
            provide a solid foundation for our collaboration. Jennie, one of
            Wasaline's representatives, impresses with her competence and skill,
            contributing to the success of our projects. The industry in which
            Wasaline operates is perceived as exciting and constantly evolving,
            providing further motivation for a rewarding collaboration between
            Wasaline and Indevit”, states Marica.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/DSC_5823-2.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Jennie Hinz, Wasaline" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Utilization of Power BI " />
          <BodyText>
            Companies in the freight and logistics sector have much to gain from
            using effective analytics tools. In an industry where swift and
            well-informed decisions are crucial, access to reliable data can
            make a significant difference. The demands on the industry are
            constantly growing, especially considering sustainability and
            environmental perspectives. By using analytics tools like Power BI,
            companies in the sector can effectively monitor and optimize their
            operations to meet these demands. Power BI offers a cost-effective
            solution that provides quick access to visualized data, making it
            easier to understand trends and make well-informed decisions. 
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/wasaline-04.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Image: Wasaline" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Reflections by Wasaline personnel" />
          <BodyText>
            Jennie Hinz at Wasaline, holds the position of Analytics and Systems
            Manager, CSO (Chief Security Officer). She acts as the overall
            project manager and is responsible for project and budget
            monitoring. Additionally, she develops and implement technical
            solutions and ensure data security. She also handles analysis
            requests to support decision-making and provide insights to optimize
            business processes and enhance customer experience through deep
            insights into Wasaline’s booking system and its data. For her, Power
            BI is a central part of her daily work for several reasons.
          </BodyText>
          <BodyText>
            “I use it to address specific questions by conducting various
            analyses based on our business data, assisting decision-making by
            extracting and presenting relevant information in an understandable
            manner. Additionally, I utilize Power BI to monitor trends and track
            guidelines within our operations, creating overview visuals and
            dashboards to swiftly identify any deviations or opportunities.
            Lastly, Power BI enables me to perform in-depth analyses to gain a
            detailed understanding of business processes and identify areas for
            improvement or business opportunities”, says Jennie. 
          </BodyText>
          <ArticleQuote body=" “I would warmly recommend Indevit - every day of the week, our collaboration has been a great experience!”" />
          <QuoteAuthor body="– Tony Ehrs, Wasaline" />
          <BodyText>
            Tony Ehrs, working as the Freight Director at Wasaline, is
            responsible for the entire freight operation. He leads the
            development and increases Wasaline’s market share through customer
            sales and marketing of their freight services. He also serves as a
            resource for his colleagues by providing analytical support and
            continuously improving their processes and results within the
            freight operation. In his daily work, he uses Power BI to gain a
            comprehensive view of Wasaline’s freight operations, both in terms
            of volume and economics.
          </BodyText>
          <BodyText>
            “By analyzing data in Power BI, I can identify trends and patterns,
            as well as make comparisons to determine which customers or partners
            are performing differently. Furthermore, I utilize Power BI to
            generate internal and external reports on freight data, aiding us in
            making informed decisions and optimizing our operations.” states
            Tony.
          </BodyText>
          <BodyText>
            Tony sums up with the final words: "I would warmly recommend Indevit
            - every day of the week, our collaboration has been a great
            experience!" 
          </BodyText>
        </AlignmentContainer>

        {/* <AlignmentContainer flex="1" width="100%">
          <Carousel />
        </AlignmentContainer> */}

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
