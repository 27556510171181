import React from "react";
import { Section } from "../../../layout/Section";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import HeadPara from "../../../HeadPara";
import ArticleTag from "../../../ArticleTag";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { Box, Flex } from "@chakra-ui/react";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";

export const KontrollArticle = () => {
  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <AlignmentContainer>
          <HeadPara
            large
            para="Making Kontroll's workday easier with integrations"
          />
          <ArticleTags>
            <ArticleTag tag="Customer" value="Kontroll" />
            <ArticleTag tag="Project" value="Integration" />
            <ArticleTag
              tag="What we worked on"
              value="Development, Project management"
            />
          </ArticleTags>
          <BodyText bold>
            For many businesses, smooth system integrations are essential for
            efficient operations. When the accounting firm Kontroll in Vasa
            faced challenges connecting a client cash register system with an
            invoicing program, an integration became the solution that brought
            structure and saved time.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/KontrollMaricaChristoffer.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Through short and regular status meetings, Kontroll could clearly communicate their needs throughout the process." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <BodyText>
            In the fall, Kontroll partnered with Indevit, resulting in a quick
            and cost-effective solution. Through short and regular status
            meetings, Kontroll could clearly communicate their and their
            client's needs throughout the process. The result was a seamless
            integration that solved their problem and streamlined their
            workflow. Their client also benefited from the integration, enjoying
            more polished and customized invoices.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Kontroll-02.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Marica Öst, Indevit and Lotta Nygård, Kontroll." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <BodyText>
            Kontroll is an accounting firm offering services in payroll
            management, accounting, and tax law consulting. Known for their
            precision and client-focused approach, they ensure their clients’
            finances stay on track. With the new integration in place, they can
            now work even more efficiently and focus on delivering added value
            to their customers.
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
