import { GridItem, GridItemProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface GridItemVisualProps extends GridItemProps {
  image: ReactNode;
}

const GridItemVisual: React.FC<GridItemVisualProps> = ({ image, ...props }) => {
  return (
    <GridItem
      height="12rem"
      borderRadius="15.626px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      m="0"
      {...props}
    >
      {image}
    </GridItem>
  );
};

export default GridItemVisual;
