import React from "react";
import { MetaTags } from "../components/MetaTags";
import { Layout } from "../components/layout/Layout";
import { WasalineHero } from "../components/sections/insight/Wasaline/WasalineHero";
import { WasalineArticle } from "../components/sections/insight/Wasaline/WasalineArticle";
import { KontrollArticle } from "../components/sections/insight/Kontroll/KontrollArticle";
import { KontrollHero } from "../components/sections/insight/Kontroll/KontrollHero";

const KontrollPage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<KontrollHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <KontrollArticle />
    </Layout>
  );
};

export default KontrollPage;

export const Head = () => <MetaTags title="Kontroll" />;
